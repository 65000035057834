<template>
  <div>
    <div
      v-if="variants.length"
      >
      <v-card
        flat
        color="grey lighten-4"
        class="rounded-lg"
        >
        <v-card-text
          >
          <template
            v-for="(option, name, idx) in options"
            >
            <div
              class="mb-1 caption"
              >
              <div>
                {{ name }}
              </div>

              <div
                v-if="idx && second"
                >
                <template
                  v-for="ov in option"
                  >
                  <v-chip
                    small
                    class="ma-1"
                    @click="second = ov"
                    :color="second == ov ? 'primary' : ''"
                    >
                    {{ ov.optionValue.value }}
                  </v-chip>
                </template>
              </div>
              <div
                :key="first.id"
                v-else
                >
                <template
                  v-for="ov in option"
                  >
                  <v-chip
                    small
                    class="ma-1"
                    @click="first = ov"
                    :color="first == ov ? 'primary' : ''"
                    >
                    {{ ov.optionValue.value }}
                  </v-chip>
                </template>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </div>

    <v-alert
      class="rounded-lg mt-4 ml-auto py-2"
      border="right"
      color="primary"
      style="width: fit-content"
      v-if="selected"
      >
      <template
        v-if="(selected.displayPrice)"
        >
        <span
          class="text-h6 font-weight-medium white--text"
          >
          {{ selected.displayPrice }}
        </span>
      </template>

      <template
        v-else
        >
        <div
          class="d-flex align-center"
          style="gap: 8px"
          >
          <div 
            class="white--text body-2"
            >
            <div
              class="caption"
              >
              Antes
            </div>
            <div
              class="text-decoration-line-through"
              >
              {{ selected.displayPrice }}
            </div>
          </div> 

          <div
            class="text-h5 font-weight-bold secondary--text"
            >
            {{ selected.displayPrice }}
          </div>
        </div>
      </template>
    </v-alert>

    <div
      v-if="selected"
      class="mt-2 justify-end d-flex"
      style="gap: 8px"
      >
      <template
        v-if="currentUser"
        >
        <report
          :product="product"
          @reload="reportProduct"
          ></report>

        <v-menu
          style="background-color: transparent"
          content-class="elevation-0"
          offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              small
              v-bind="attrs"
              v-on="on"
              >
              <v-icon>mdi-share</v-icon> Compartir
            </v-btn>
          </template>
          <div
            v-for="type in ['want_it', 'recommend_it', 'love_it']"
            class="mt-1"
            >
            <create-post
              :postType="type"
              :product="product"
              small
              color="primary"
              ></create-post>
          </div>
        </v-menu>
      </template>

      <v-btn
        v-if="selected.stock > 0"
        outlined
        small
        color="primary"
        @click="addItem"
        :disabled="(currentUser && currentUser.selectedProfile.profilableType == 'Store') || !product.active"
        >
        Comprar
        <v-icon
          size="20"
          style="margin-top: -2px"
          class="ml-1"
          >
          mdi-shopping
        </v-icon>
      </v-btn>

      <v-btn 
        v-else
        small
        disabled
        color="primary"
        >
        Sin stock
      </v-btn>
    </div>

    <v-alert
      v-if="reported"
      class="mt-2 mb-0"
      dense
      outlined
      border="left"
      type="error"
      icon="mdi-information"
      dismissible
      >
      Reportaste este producto.
    </v-alert>

    <v-alert
      v-if="currentUser && (currentUser.selectedProfile.profilableType == 'Store')"
      class="mt-2 mb-0"
      dense
      type="info"
      outlined
      >
      Para realizar una compra deberá ingresar con su perfil Fan.
    </v-alert>

    <v-alert
      v-if="!product.active"
      class="mt-2 mb-0 rounded-lg"
      dense
      color="orange"
      border="left"
      icon="mdi-pause-circle-outline"
      outlined
      >
      Publicación pausada.
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Variants } from '@/graphql/queries/variants'
import { OrderService } from '@/services/storageService'
import { AddItem } from '@/graphql/mutations/order' 

const CreatePost = () => import('@/components/posts/Create')
const Report = () => import('@/components/products/Report')

export default {
  data () {
    return {
      selected: null,
      reported: false,
      variants: [],
      options: [],
      first: {},
      second: {},
      quantity: 1
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    quantityWatcher () {
      return [this.selected, this.quantity]
    }
  },

  watch: {
    currentUser () {
      this.reported = this.currentUser.reportedProductsIds.includes(this.product.id)
    },

    variants (val) {
      if (this.variants.length) {
        var opts = []

        this.variants.forEach ( v => {
          v.variantOptionValues.forEach ( ov => {
            opts.push(ov)
          })
        })

        opts = opts.reduce(function(rv, x) {
          (rv[x.optionValue.optionType.name] = rv[x.optionValue.optionType.name] || []).push(x);
          return rv;
        }, {});

        var aux = {}

        Object.entries(opts).forEach ( a => {
          aux[a[0]] = a[1].reduce((unique, o) => {
            if(!unique.some(obj => obj.optionValue.value === o.optionValue.value)) {
              unique.push(o);
            }
            return unique;
          },[]);
        })

        aux = Object.keys(aux).sort().reduce(
          (obj, key) => {
            obj[key] = aux[key];
            return obj;
          },
          {}
        );

        this.first = Object.values(aux)[0][0]

        this.options = aux
      }
    },

    quantityWatcher () {
      if (this.quantity > this.selected.stock) {
        this.quantity = this.selected.stock
      } else if (this.quantity < 1 && this.selected.stock > 0) {
        this.quantity = 1
      }
    },

    first () {
      var opts = [], variants = this.variants.filter( v => { return v.optionValueIds.includes(this.first.optionValueId) })

      variants.forEach ( v => {
        v.variantOptionValues.forEach ( ov => {
          opts.push(ov)
        })
      })

      opts = opts.reduce(function(rv, x) {
        (rv[x.optionValue.optionType.name] = rv[x.optionValue.optionType.name] || []).push(x);
        return rv;
      }, {});

      var aux = {}

      Object.entries(opts).forEach ( a => {
        aux[a[0]] = a[1].reduce((unique, o) => {
          if(!unique.some(obj => obj.optionValue.value === o.optionValue.value)) {
            unique.push(o);
          }
          return unique;
        },[]);
      })

      aux = Object.keys(aux).sort().reduce(
        (obj, key) => {
          obj[key] = aux[key];
          return obj;
        },
        {}
      );

      var key = Object.keys(aux)[1]

      if (key) {
        this.options[key] = aux[key]

        this.second = aux[key][0]
      } else {
        this.selected = this.first.variant
      }
    },

    second () {
      this.selected = this.second.variant
    },

    selected () {
      this.$emit('select', this.selected)
      console.log(this.selected)
    }
  },


  props: {
    product: {
      type: Object,
      required: true
    },
  },

  created () {
    this.fetchVariants ()

    if (this.currentUser) this.reported = this.currentUser.reportedProductsIds.includes(this.product.id)
  },

  methods: {
    fetchVariants () {
      this.$apollo.query({
        query: Variants,
        variables: {
          productId: this.product.id
        }
      }).then( res => {
        this.variants = res.data.variants

        if (!this.variants.length) {
          this.selected = this.product.master
        }
      })
    },

    addItem () {
      this.$apollo.mutate({
        mutation: AddItem,
        variables: {
          input: {
            itemId: this.selected.id,
            orderToken: OrderService.getToken(),
            quantity: this.quantity,
            fairId: localStorage.getItem('orderFairId')
          }
        }
      }).then( res => {
        OrderService.saveToken(res.data.addItem.order.orderToken)
        this.$router.push({ name: 'checkout' })
      })
    },

    reportProduct () {
      this.reported = true
    }
  },

  components: { CreatePost, Report }
}
</script>
