<template>
  <div>
    <div
      class="text-h6 px-2 mb-2"
      >
      Descripción
    </div>
    <v-card
      outlined
      rounded="lg"
      >
      <v-card-text
        class="pa-2"
        >
        {{ product.description }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
