<template>
  <div
    v-if="properties.length"
    >
    <div
      class="text-h6 px-2 mb-2"
      >
      Especificaciones
    </div>
    <v-card
      outlined
      rounded="lg"
      style="overflow: hidden"
      >
      <v-card-text
        class="pa-0"
        >
        <div
          :class="$vuetify.breakpoint.lgAndUp ? 'b-container' : ''"
          >
          <template
            v-for="(property, idx) in properties"
            >
            <div
              :class="idx % 2 == 0 ? 'grey lighten-3' : ''"
              class="fill-width d-flex flex-row justify-space-between pa-4"
              >
              <div>
                {{ property.name }}
              </div>
              <div>
                {{ property.value }}
              </div>
            </div>
            <v-divider
              v-if="idx + 1 < properties.length"
              ></v-divider>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SimpleProperties } from '@/graphql/queries/products'

export default {
  data () {
    return {
      properties: []
    }
  },

  props: {
    id: {
      type: String | Number,
      required: false
    }
  },

  mounted () {
    this.fetchProperties()
  },

  methods: {
    fetchProperties () {
      this.$apollo.query({
        query: SimpleProperties,
        variables: {
          id: this.id
        }
      }).then ( res => {
        this.properties = res.data.productSimpleProperties
      })
    }
  }
}
</script>

<style scoped>
.b-container {
  overflow-y: scroll;
  max-height: 313px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.b-container::-webkit-scrollbar {
  display: none;
}
</style>
