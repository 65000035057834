<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="1100"
    v-model="dialog"
    >
    <v-card
      v-if="product"
      class="d-flex flex-column flex-md-row"
      >
      <v-carousel 
        v-if="images.length"
        :height="$vuetify.breakpoint.mdAndDown ? height : '602.750px'"
        ref="image"
        hide-delimiter-background
        :show-arrows="images.length > 1"
        >
        <v-carousel-item
          v-for="(image, i) in images"
          height="300"
          :key="image.id"
          :src="url + image.url"
          class="text-right"
          contain
          >
          <v-btn
            fab
            class="ma-2"
            small
            depressed
            @click="back"
            >
            <v-icon
              >
              mdi-close
            </v-icon>
          </v-btn>
        </v-carousel-item>
      </v-carousel>

      <v-img
        v-else
        height="300"
        class="text-right"
        >
        <v-alert
          style="position: absolute; bottom: 36px; left: 0; z-index: 10"
          class="rounded-l-0 rounded-r-lg"
          border="left"
          color="grey darken-4"
          >
          <template
            v-if="(product.displayFullPrice == product.displayPrice)"
            >
            <span
              class="text-h6 font-weight-medium secondary--text"
              >
              {{ product.displayPrice }}
            </span>
          </template>

          <template
            v-else
            >
            <div
              class="d-flex align-center"
              style="gap: 8px"
              >
              <div 
                class="white--text body-2"
                >
                <div
                  class="caption"
                  >
                  Antes
                </div>
                <div
                  class="text-decoration-line-through"
                  >
                  {{ product.displayFullPrice }}
                </div>
              </div> 

              <div
                class="text-h5 font-weight-bold secondary--text"
                >
                {{ product.displayPrice }}
              </div>
            </div>
          </template>

          <v-icon
            color="secondary"
            style="position: absolute; right: -8px; bottom: -8px"
            >
            mdi-tag
          </v-icon>
        </v-alert>
        <v-btn
          fab
          class="ma-2"
          small
          depressed
          @click="back"
          >
          <v-icon
            >
            mdi-close
          </v-icon>
        </v-btn>
      </v-img>

      <div
        class="py-md-3 pr-md-3 pl-md-6 grow"
        :style="$vuetify.breakpoint.mdAndUp ? 'max-height: 602px; overflow-y: auto; width: 488px; scrollbar-gutter: stable;' : ''"
        >
        <store
          v-if="store"
          :store="store"
          :stylish="$vuetify.breakpoint.mdAndUp"
          ></store>

        <v-card-title
          class="align-center pb-2"
          >
          <div>
            {{ product.name }}
          </div>
        </v-card-title>

        <v-card-subtitle
          class="d-flex flex-column fill-width mt-0"
          >
          <buy
            ref="buy"
            :product="product"
            @select="select"
            ></buy>
        </v-card-subtitle>

        <v-btn-toggle
          mandatory
          v-model="tab"
          tile
          group
          style="height: 48px"
          class="fill-width"
          >
          <v-btn
            value="detail"
            >
            <div
              class="d-flex flex-column"
              >
              <v-icon
                :color="tab == 'detail' ? 'primary' : 'black'"
                >
                {{ tab == 'detail' ? 'mdi-post' : 'mdi-post-outline' }}
              </v-icon>
            </div>
          </v-btn>
          <v-btn
            v-if="false"
            value="comments"
            >
            <div
              class="d-flex"
              >
              <v-icon
                :color="tab == 'comments' ? 'primary' : 'black'"
                >
                {{ tab == 'comments' ? 'mdi-comment-question' : 'mdi-comment-question-outline' }}
              </v-icon>
            </div>
          </v-btn>
          <v-btn
            value="rate"
            >
            <div
              class="d-flex align-center"
              >
              <v-icon
                v-if="true"
                :color="tab == 'rate' ? 'primary' : 'black'"
                >
                {{ tab == 'rate' ? 'mdi-star' : 'mdi-star-outline' }}
              </v-icon>
              <v-rating
                v-else
                :color="tab == 'rate' ? 'primary' : 'black'"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                hover
                length="5"
                size="13"
                dense
                readonly
                :value="product.rating.rating"
                ></v-rating>
            </div>
          </v-btn>
          <v-btn
            value="price"
            >
            <div
              class="d-flex align-center"
              >
              <v-icon
                :color="tab == 'price' ? 'primary' : 'black'"
                >
                {{ tab == 'price' ? 'mdi-cash-multiple' : 'mdi-cash' }}
              </v-icon>
            </div>
          </v-btn>
        </v-btn-toggle>

        <v-divider
          class="mb-2"
          ></v-divider>

        <v-card-text
          class="px-2"
          >
          <template
            v-if="tab == 'detail'"
            >
            <detail
              :product="product"
              ></detail>
            <specifications
              class="mt-3"
              :id="product.id"
              ></specifications>
          </template>

          <template
            v-if="tab == 'comments'"
            >
            <questions></questions>
          </template>

          <template
            v-if="tab == 'rate'"
            >
            <rates
              :product="product"
              ></rates>
          </template>

          <template
            v-if="tab == 'price'"
            >
            <prices
              :product="product"
              ></prices>
          </template>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { Product } from '@/graphql/queries/products';
import { ProductDetail } from '@/graphql/queries/stores';

import Detail from '@/components/products/Detail'
import Buy from '@/components/products/Buy'
import Specifications from '@/components/products/Specifications'

const Store = () => import('@/components/posts/Store')
const Questions = () => import('@/components/products/Questions')
const Rates = () => import('@/components/products/Rates')
const Prices = () => import('@/components/products/Prices')

export default {
  data:() => ({
    tab: 'detail',
    dialog: false,
    store: null,
    product: null,
    selected: null,
    height: '100vw',
  }),

  props: {
    productId: {
      required: true,
      type: String | Number
    }
  },

  created () {
    this.fetchProduct ()

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      if (!this.dialog || ['checkout', 'admin', 'profile', 'signup'].includes(to.name)) {
        next()
      } else {
        this.back()

        next(false)
      }
    })

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    })
  },

  methods: {
    fetchProduct () {                                                                        
      this.$apollo.query({                                                                 
        query: Product,                                                              
        variables: {                                                                        
          id: this.productId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {      
        this.product = res.data.product

        this.fetchStore ()

        this.$nextTick( () => {
          if (this.$refs.image) {
            this.height = this.$refs.image.$el.offsetWidth
          }
        })
      }).catch(errors => {                                                                  
      })                                                                                       
    },

    fetchStore () {
      this.$apollo.query({
        query: ProductDetail,
        variables: {
          slug: this.product.storeId
        }
      }).then ( res => {
        this.store = res.data.publicStore
      })
    },

    back () {
      this.dialog = false
      this.$emit('close')
    },

    select (val) {
      this.selected = val

      this.$forceUpdate()
    }
  },

  computed: {
    url () {
      return process.env.VUE_APP_RAILS_URL
    },

    images () {
      if (this.selected && this.selected.images.length) {
        return this.selected.images
      } else {
        return this.product.images
      }
    }
  },

  components: {
    Detail, Specifications, Questions, Rates, Prices, Buy, Store
  }
}
</script>
