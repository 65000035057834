import gql from 'graphql-tag'
import { BaseVariant as BaseVariantFragment } from '@/graphql/fragments'
import { Variant as VariantFragment } from '@/graphql/fragments'

export const Variants = gql`
  query variants($productId: ID!) {
    variants(productId: $productId) {
      ...${ BaseVariantFragment }
    }
  } 
`

export const AdminVariants = gql`
  query variants($productId: ID!) {
    variants(productId: $productId) {
      ...${ VariantFragment }
    }
  } 
`
